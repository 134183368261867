import { apiSlice } from ".";
import { generateQueryString } from "../../core/utils/generateQueryString";

export const nicheApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllNiche: builder.query({
      query: (args) => {
        const { queryString } = generateQueryString({
          queryObject: {
            page_size: 15,
            page: 1,
            ...(args || {}),
          },
        });
        return {
          url: `/seo-analysis/v1/niche/${queryString}`,
        };
      },
      providesTags: ["getAllNiche"],
    }),

    getNicheById: builder.query({
      query: (id) => {
        return {
          url: `/seo-analysis/v1/affiliate-marketplace/${id}`,
        };
      },
      providesTags: ["getNicheById"],
    }),

    createNiche: builder.mutation({
      query: (data) => {
        return {
          url: "/seo-analysis/v1/niche/",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["getAllNiche", "getNicheById"],
    }),

    updateNicheById: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/seo-analysis/v1/niche/${id}`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["getAllNiche", "getNicheById"],
    }),

    deleteNicheById: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/seo-analysis/v1/affiliate-marketplace/${id}`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["getAllNiche", "getNicheById"],
    }),
  }),
});

export const {
  useGetAllNicheQuery,
  useGetNicheByIdQuery,
  useCreateNicheMutation,
  useUpdateNicheByIdMutation,
  useDeleteNicheByIdMutation,
} = nicheApiSlice;
