import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editorContent: ``,
  wordCount: 0,
  editorStateVersions: [],
};

export const editorSilce = createSlice({
  name: "editor",
  initialState,
  reducers: {
    setEditorContent: (state, { payload }) => {
      state.editorContent = payload;
    },
    setWordCount: (state, { payload }) => {
      // console.log(payload);
      state.wordCount = payload;
    },
    setEditorStateVersions: (state, { payload }) => {
      console.log(payload, "payload");
      if (payload == "clear") {
        state.editorStateVersions = [];
      } else if (
        state.editorStateVersions?.length > 0 &&
        state.editorStateVersions?.length < 4
      ) {
        state.editorStateVersions = [payload, ...state.editorStateVersions];
      } else if (state.editorStateVersions?.length == 0) {
        state.editorStateVersions = [payload];
      } else if (state.editorStateVersions?.length == 4) {
        state.editorStateVersions.pop();
        state.editorStateVersions = [payload, ...state.editorStateVersions];
      }
    },
  },
});

export const { setEditorContent, setWordCount, setEditorStateVersions } =
  editorSilce.actions;

export default editorSilce.reducer;
