import { apiSlice } from ".";
import { generateQueryString } from "../../core/utils/generateQueryString";

export const affiliateMarketplaceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllAffiliateMarketplace: builder.query({
      query: (args) => {
        const { queryString } = generateQueryString({
          queryObject: { page_size: 15, page: 1, ...(args || {}) },
        });
        return {
          url: `/seo-analysis/v1/affiliate-marketplace/${queryString}`,
        };
      },
      providesTags: ["getAllAffiliateMarketplace"],
    }),

    getAffiliateMarketplaceById: builder.query({
      query: (id) => {
        return {
          url: `/seo-analysis/v1/affiliate-marketplace/${id}`,
        };
      },
      providesTags: ["getAffiliateMarketplaceById"],
    }),

    createAffiliateMarketplace: builder.mutation({
      query: (data) => {
        return {
          url: "/seo-analysis/v1/affiliate-marketplace/",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [
        "getAllAffiliateMarketplace",
        "getAffiliateMarketplaceById",
      ],
    }),

    updateAffiliateMarketplaceById: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/seo-analysis/v1/affiliate-marketplace/${id}`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [
        "getAllAffiliateMarketplace",
        "getAffiliateMarketplaceById",
      ],
    }),

    deleteAffiliateMarketplaceById: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/seo-analysis/v1/affiliate-marketplace/${id}`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [
        "getAllAffiliateMarketplace",
        "getAffiliateMarketplaceById",
      ],
    }),
  }),
});

export const {
  useGetAllAffiliateMarketplaceQuery,
  useGetAffiliateMarketplaceByIdQuery,
  useCreateAffiliateMarketplaceMutation,
  useUpdateAffiliateMarketplaceByIdMutation,
  useDeleteAffiliateMarketplaceByIdMutation,
} = affiliateMarketplaceApiSlice;
