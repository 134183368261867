import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productList: [],
  withdrawHistory: {},
  couponInfo: null,
  autoApply: null,
  planId: null,
  couponKey: null,
};

export const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    setProductList: (state, { payload }) => {
      state.productList = [...payload];
    },

    setWithdrawHistory: (state, { payload }) => {
      state.withdrawHistory = payload;
    },
    setCouponInfo: (state, { payload }) => {
      state.couponInfo = payload;
    },
    setAutoApply: (state, { payload }) => {
      state.autoApply = payload;
    },
    setPlanId: (state, { payload }) => {
      state.planId = payload;
    },
    setCouponKey: (state, { payload }) => {
      state.couponKey = payload;
    },
    billingLogoutAction: (state) => initialState,
  },
});

export const {
  setProductList,
  setWithdrawHistory,
  billingLogoutAction,
  setCouponInfo,
  setAutoApply,
  setPlanId,
  setCouponKey,
} = billingSlice.actions;

export default billingSlice.reducer;
