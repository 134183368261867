// export const BASE_EPI_URL = "https://backend.smartoutreach.net/api";
export const BASE_EPI_URL = process.env.REACT_APP_API_URL;
export const BASE_APP_URL = process.env.REACT_APP_BASE_URL;

export const ROUTES = {
  home: "/",
  login: "/login",
  signupId: "/signup/:id",
  signup: "/signup",
  forgetPassword: "/forget-password",
  resetPassword: "/reset-password",
  dashboard: "/dashboard",
  allFeatures: "/all-features",
  infoArticle: "/info-article",
  shortArticle: "/short-article",
  infoArticleEditor: "/info-article-editor",
  biographyArticle: "/biography-article",
  biographyArticleEditor: "/biography-article-editor",
  manualSubheading: "/manual-subheading",
  reviewArticle: "/review-article",
  manualReviewArticle: "/manual-review-article",
  assistedWriter: "/assisted-writer",
  keywordStuffing: "/keyword-stuffing-article",
  affiliateContentGenerator: "/affiliate-content-generator",
  vsAffiliateContentGenerator: "/vs-affiliate-content-generator",
  websiteList: "/website-list",
  amazonApiList: "/amazon-api-list",
  postHisotry: "/post-history",
  tutorial: "/tutorial",
  post: "/post/:id",
  site: "/site/:id",
  wordpressAuthenticate: "/wordpress-authenticate",
  api: "/amazon_api/:id",
  editor: "/editor/:id",
  referalDashboard: "/referal-dashboard",
  withdrawHistory: "/referral-dashboard/withdraw-history",
  bloggerList: "/blogger-list",
  blogger: "/blogger/:id",
  mediumList: "/medium-list",
  bulkMediumList: "/bulk-medium-list",
  products: "/pricing",
  productsV2: "/pricing-v2",
  admin: "/admin",
  outlineEditor: "/outline-editor",
  adminReferralDashboard: "/admin/referral-dashboard/:id",
  adminReferralInfo: "/admin/referral-info",
  adminUserInfo: "/admin/user-info",
  adminPost: "/admin/post/:id",
  createApiKey: "/admin/create-api-key",
  mailSystem: "/admin/mail-system",
  supportAdmin: "/admin/support",
  ticketFormAdmin: "/admin/support/ticket",
  ticketIdAdmin: "/admin/support/ticket/:id",
  errorLog: "/admin/error-log",
  roles: "/admin/roles",
  taskStat: "/admin/task-stat",
  shortFeatures: "/admin/short-features", // Short Features
  editShortFeature: "/admin/edit-short-features/:id",
  createShortFeature: "/admin/create-short-feature",
  affiliateProgram: "/affiliate-program",
  affiliateProgramAdmin: "/admin/affiliate-program",
  createAffiliateProgramPage: "/admin/create-affiliate-program",
  updateAffiliateProgramPage: "/admin/update-affiliate-program/:id",
  testEnvironment: "/admin/test-environment",
  bloggerRedirect: "/blogger-redirect",
  paymentChoices: "/payment-choices",
  paymentCompletion: "/payment-completion",
  earnCredit: "/earn-free-credit",
  creditHistory: "/credit-history",
  scrapKeyword: "/scrap-keyword",
  keywordExplorerWithParams: "/keyword-explorer/:keyword",
  keywordExplorer: "/keyword-explorer",
  keywordGroupingTool: "/keyword-grouping",
  thankYouPage: "/thank-you",
  supportPage: "/support",
  amazonProductRoundup: "/amazon-product-roundup",
  resellerTransferCredit: "/transfer-credit",
  externalServices: "/services",
  externalService: "/services/:key",
  allWritingTool: "/writing-tools",
  aiToHuman: "/ai-to-human-converter",
  paymentMethod: "/payment-method",
  externalServicePayment: "/services/payment",
  ticketForm: "/support/ticket",
  ticketId: "/support/ticket/:id",
  clientGenerator: "/client-generator",
  redeemCode: "/redeem",
};

export const REST_API_ENDPOINT = {
  auth: {
    v1: {
      bloggerVerify: BASE_EPI_URL + "/accounts/v1/blogger-verfication/",
      google: BASE_EPI_URL + "/accounts/v1/login/google/",
      signin: BASE_EPI_URL + "/accounts/v1/signin/",
      signup: BASE_EPI_URL + "/accounts/v1/signup/",
      forgotPasswordRequest:
        BASE_EPI_URL + "/accounts/v1/forgot-password-request/",
      resetPasswordRequest:
        BASE_EPI_URL + "/accounts/v1/reset-password-request/",
      user_info: BASE_EPI_URL + "/accounts/v1/user-info/",
      userStatictics: BASE_EPI_URL + "/accounts/v1/user-statistics/",
      referalInfo: BASE_EPI_URL + "/accounts/v1/user-referral-info/",
      referralChartData: (days) =>
        BASE_EPI_URL +
        `/accounts/v1/referral-chart/?amount=${days}&signup=true`,
      referralTableData:
        BASE_EPI_URL +
        "/billing/v1/affiliate-referred-user/?sort=-earned_coins",
      referralClickUpdate:
        BASE_EPI_URL + "/accounts/v1/user-referral-click-update/",
      adminUserList:
        BASE_EPI_URL + "/accounts/v1/admin-user-list/?page_size=30",
      topReferralList:
        BASE_EPI_URL +
        "/accounts/v1/admin-affiliate-user-list/?page_size=100&sort=-available_referral_earnings",
      adminUserInfo: (user_id) =>
        BASE_EPI_URL + `/accounts/v1/admin-user-info/?user-id=${user_id}`,
      searchUserWithEmail: BASE_EPI_URL + "/accounts/v1/reseller-user-info",
    },
  },
  automation: {
    v1: {
      addingToESP: BASE_EPI_URL + "/automations/v1/adding-to-esp/",
      getTaskList: BASE_EPI_URL + "/automations/v1/get-task-list/",
    },
  },
  billing: {
    v1: {
      admin: {
        adminCreateTransaction:
          BASE_EPI_URL + "/billing/v1/admin-create-transaction/",
      },
      withdrawRequest: BASE_EPI_URL + "/billing/v1/create-withdraw-request/",
      withdrawHistory: BASE_EPI_URL + "/billing/v1/withdraw-history/",
      productList: BASE_EPI_URL + "/billing/v1/product-list/",
      createCheckoutSession: (id) =>
        BASE_EPI_URL + `/billing/v1/create-checkout-session/${id}/`,
      createSslCheckout: (id) =>
        BASE_EPI_URL + `/billing/v1/create-checkout-session-ssl-commerz/${id}/`,
      couponAdvertsie: BASE_EPI_URL + "/billing/v1/coupon-advertise/",
      earnPointsRequest: BASE_EPI_URL + "/billing/v1/earn-points-request/",
      resellerTransferCredit:
        BASE_EPI_URL + "/billing/v1/reseller-transfer-transaction/",
    },
  },
  task: {
    v1: {
      aiInfoArticle: BASE_EPI_URL + "/task/v1/ai-info-article/",
      aiSubHeading:
        BASE_EPI_URL + "/task/v1/ai-info-article-manual-subheading/",
      aiInfoArticleEditor: BASE_EPI_URL + "/task/v1/ai-info-article-editor/",
      biographyArticle: BASE_EPI_URL + "/task/v1/biography-article/",
      biographyArticleEditor:
        BASE_EPI_URL + "/task/v1/biography-article-editor/",
      aiReviewArticle: BASE_EPI_URL + "/task/v1/ai-review-article/",
      aiReviewSearch: BASE_EPI_URL + "/task/v1/amazon-data-fetch/",
      aiReviewAsin: BASE_EPI_URL + "/task/v1/amazon-data-fetch-asin/",
      taskUserDetails: BASE_EPI_URL + "/task/v1/task-user-details/",
      assistedOutline: BASE_EPI_URL + "/task/v1/assisted-heading-fetch/",
      assistedArticles: BASE_EPI_URL + "/task/v1/outline-editor/",
      blogParagraph: BASE_EPI_URL + "/task/v1/feature-task/",
      keywordStuffing: BASE_EPI_URL + "/task/v1/keyword-stuffing/",
      allFeatures: BASE_EPI_URL + "/task/v1/feature-list/",
      postToSite: BASE_EPI_URL + "/task/v1/website-post-task/",
      taskDetails: (post_id) => BASE_EPI_URL + `/task/v1/task-list/${post_id}`,
      adminTaskUserInfo: (post_id) =>
        BASE_EPI_URL + `/task/v1/admin-task-user-details/?task_id=${post_id}`,
      taskList: BASE_EPI_URL + "/task/v1/task-list/",
      subTaskDetails: (subtask_id) =>
        BASE_EPI_URL + `/task/v1/sub-task-list/${subtask_id}/`,
      creditHistory: BASE_EPI_URL + "/task/v1/credit-history/?page_size=15",
      shortArticle: BASE_EPI_URL + "/task/v1/short-info-article/",
      getSEOKeywords: BASE_EPI_URL + "/task/v1/sitemap-to-title/",
      wordFrequency: BASE_EPI_URL + "/seo-analysis/v1/word-frequency/",
      updatetask: BASE_EPI_URL + "/task/v1/update-task/",
      clientGenerator: BASE_EPI_URL + "/task/v1/client-generator/",
      affiliateContentGeneration:
        BASE_EPI_URL + "/task/v1/affiliate-content-generator/",
      adminErrorLogs: BASE_EPI_URL + "/task/v1/admin/subtask-inspect/",
    },
  },
  site_manager: {
    v1: {
      baseAmazonSite: BASE_EPI_URL + "/site_manager/v1/amazon-site/",
      baseWordpressSite: BASE_EPI_URL + "/site_manager/v1/wordpress-site/",
      amazonSite: BASE_EPI_URL + "/site_manager/v1/amazon-site/?page_size=15",
      bloggerSite:
        BASE_EPI_URL + "/site_manager/v1/blogger-website/?page_size=15",
      wordpressSite:
        BASE_EPI_URL + "/site_manager/v1/wordpress-site/?page_size=15",
      wordpressSiteDetails: (site_id) =>
        BASE_EPI_URL + `/site_manager/v1/wordpress-site/${site_id}`,
      amazonSiteDetails: (site_id) =>
        BASE_EPI_URL + `/site_manager/v1/amazon-site/${site_id}`,
      bloggerSiteDetails: (site_id) =>
        BASE_EPI_URL + `/site_manager/v1/blogger-website/${site_id}`,
      verifySite: BASE_EPI_URL + "/site_manager/v1/wordpress-details/",
      mediumSites: BASE_EPI_URL + "/site_manager/v1/medium-website/",
    },
  },
  notification: {
    v1: {
      getNotification:
        BASE_EPI_URL + "/notification/v1/notifications/?page_size=100",
    },
  },
  config: {
    v1: {
      apiKeyCreate: BASE_EPI_URL + "/config/v1/api-key-create/",
    },
  },
  seoAnalysis: {
    v1: {
      keywordExplorer: BASE_EPI_URL + "/seo-analysis/v1/keyword-suggestion/",
      testTimeout: BASE_EPI_URL + "/seo-analysis/v1/test-timeout/",
    },
  },
  mailManagement: {
    v1: {
      mailSender: BASE_EPI_URL + "/mail-management/v1/send-mail/",
    },
  },
  externalService: {
    v1: {
      serviceType: BASE_EPI_URL + "/external-service/v1/service-type/",
      serviceList: BASE_EPI_URL + "/external-service/v1/service-list/",
      servicePayment:
        BASE_EPI_URL + "/external-service/v1/create-checkout-session/",
      createSslCheckout: (id) =>
        BASE_EPI_URL +
        `/external-service/v1/create-checkout-session-ssl-commerz/${id}/`,
    },
  },
};
